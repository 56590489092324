import React from 'react'
import { Pagination } from 'react-bootstrap'
import * as PropTypes from 'prop-types'

const getPages = (currentPage, range, pageCount) => {
  let itemsToAdd = (range * 2)

  const pages = new Set()
  pages.add(currentPage)

  if (currentPage - range > 2) {
    itemsToAdd--
  }
  if (currentPage - range > 1) {
    pages.add(0)
    itemsToAdd--
  }

  if (currentPage + range < pageCount - 1) {
    itemsToAdd--
  }
  if (currentPage + range < pageCount) {
    pages.add(pageCount)
    itemsToAdd--
  }

  let currentMin = currentPage - 1
  let currentMax = currentPage + 1

  while (itemsToAdd > 0) {
    if (currentMin >= 1) {
      pages.add(currentMin)
      currentMin--
      itemsToAdd--
    }
    if (currentMax <= pageCount) {
      pages.add(currentMax)
      itemsToAdd--
      currentMax++
    }
    if (currentMin < 1 && currentMax > pageCount) {
      break
    }
  }
  return Array.from(pages).sort((p1, p2) => p1 - p2)
}

export default function TutorialsPagination ({
  countTutorials,
  itemsPerPage,
  currentPage,
  onPageChanged
}) {
  const handlePageChanged = (e) => {
    const currentPage = parseInt(e.target.dataset.page)
    onPageChanged(currentPage)
    window.scrollTo(0, 0)
  }

  const onPrevClick = (currentPage) => {
    onPageChanged(currentPage - 1)
    window.scrollTo(0, 0)
  }

  const onNextClick = (currentPage) => {
    onPageChanged(currentPage + 1)
    window.scrollTo(0, 0)
  }

  const PaginationItem = ({ page }) => (
    <Pagination.Item
      key={page}
      data-page={page}
      onClick={(e) => handlePageChanged(e)}
      active={page === parseInt(currentPage)}
      className='rounded mx-1'
    >
      {page + 1}
    </Pagination.Item>
  )

  const range = 2
  const pageCount = Math.ceil(countTutorials / itemsPerPage)

  const shownNumbers = getPages(currentPage, range, pageCount - 1)

  const items = []
  for (let i = 0; i < shownNumbers.length; i++) {
    const currentNumber = shownNumbers[i]
    if (i > 0) {
      const previousNUmber = shownNumbers[i - 1]
      const gap = currentNumber - previousNUmber
      if (gap === 2) {
        items.push(<PaginationItem key={currentNumber - 1} page={currentNumber - 1} />)
      } else if (gap > 2) {
        items.push(<Pagination.Ellipsis key={`ellipsis-${i}`} />)
      }
    }
    items.push(<PaginationItem key={currentNumber} page={currentNumber} />)
  }

  const showPrev = currentPage !== 0
  const showNext = currentPage !== pageCount - 1

  return (
    <div className='d-flex justify-content-center mt-4'>
      <Pagination
        className='font-weight-bold'
      >
        {showPrev &&
          <Pagination.Prev
            className='rounded'
            onClick={() => onPrevClick(currentPage)}
          >
            <i className='fa fa-chevron-left' />
          </Pagination.Prev>}
        {items}
        {showNext &&
          <Pagination.Next
            className='rounded'
            onClick={() => onNextClick(currentPage)}
          >
            <i className='fa fa-chevron-right' />
          </Pagination.Next>}
      </Pagination>
    </div>
  )
}

TutorialsPagination.propTypes = {
  countTutorials: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  onPageChanged: PropTypes.any
}
