import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Layout from '../components/layout'
import { TutorialEntries } from '../components/Tutorials/TutorialEntry/TutorialEntry'
import { Container, FormControl, InputGroup, Jumbotron } from '@headtrip/components-react'
import Fuse from 'fuse.js'
import { CommunityBreadcrumbs } from '../components/CommunityBreadcrumbs'
import TutorialsHeaderBG from '../images/community/startpage-header-hetzner-community.webp'
import { Col, Row } from 'react-bootstrap'

import 'url-search-params-polyfill'
import SupplementSection from '../components/Various/SupplementSection/SupplementSection'
import Pagination from '../components/Navigation/Pagination/TutorialsPagination'
import SEO from '../components/seo'

class FilterProvider extends Component {
  constructor (props, context) {
    super(props, context)

    this.tutorials = FilterProvider.generateTutorialObjects(this.props.data)

    // TODO doing crazy checkings bc. of XSS
    let tags = []
    this.tutorials.forEach(tutorial => {
      tutorial.tags.forEach(tag => {
        tags.push(tag)
      })
    })

    this.tags = new Set(tags)

    const urlSearchParams = new URLSearchParams(props.search)
    let allSearchValues = urlSearchParams.getAll('search').join('').toString()

    this.state = {
      search: this.tags.has(allSearchValues) ? allSearchValues : ''
    }

    this.fuse = new Fuse(this.tutorials, {
      shouldSort: true,
      tokenize: false,
      threshold: 0.4,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 2,
      keys: ['title', 'short_description', 'author', 'tags']
    })

    this.onSearch = this.onSearch.bind(this)
  }

  static generateTutorialObjects (data) {
    return data.allMarkdownRemark
      .edges.map(edge => {
        return { ...edge.node.frontmatter }
      }).filter(frontmatter => frontmatter.lang === 'en')
  }

  async onSearch (data) {
    this.setState({ search: data })
  }

  render () {
    // let tags = this.tutorials.map(tutorial => tutorial.tags).flat()
    // tags = Array.from(new Set(tags))
    let tags = []
    return <TutorialPageLayout
      search={this.state.search}
      tutorials={this.state.search.length > 1 ? this.fuse.search(this.state.search).map(element => element.item) : this.tutorials}
      onSearch={this.onSearch}
      totalAmountTutorials={this.tutorials.length} tags={tags}
    />
  }
}

function SearchField (props) {
  return <InputGroup>
    <InputGroup.Prepend>
      <InputGroup.Text className='border-0 bg-gray-400 text-black '><i className='fa fa-search' /></InputGroup.Text>
    </InputGroup.Prepend>
    {/* eslint-disable-next-line max-len */}
    <FormControl value={props.value} placeholder={`Search ${ props.totalAmountTutorials } tutorials`} className='bold' onChange={e => props.onSearch(e.target.value)} />
  </InputGroup>
}

const TutorialsHeader = props => {
  // eslint-disable-next-line max-len
  return <Jumbotron className={props.className + ' mb-0'} fluid style={{ backgroundImage: 'url(' + TutorialsHeaderBG + ')', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'left' }}>
    <Container>
      {props.children}
    </Container>
  </Jumbotron>
}

class TutorialPageLayout extends Component {
  /* tutorialData */
  state = {
    currentPage: 0,
  }

  onSearch = e => {
    this.setState({ currentPage: 0 })
    this.props.onSearch(e)
  }

  onPageChanged = currentPage => {
    this.setState({ currentPage })
  }

  render () {
    let props = this.props
    let pathName = '/tutorials'
    let { currentPage } = this.state
    let itemsPerPage = 10
    let tutorialsOnPage = Array.from(props.tutorials).splice(itemsPerPage * currentPage, itemsPerPage)

    return <Layout noHeader location={{ 'pathname': pathName }}>
      {/* eslint-disable-next-line max-len */}
      <SEO title={'Tutorials'} description={'Get mad skills. Hetzner Community is a place that’s designed for you to share, grow and improve.'} path={'tutorials'} type={'website'} locale={'en'}/>
      <main>
        <TutorialsHeader>
          <CommunityBreadcrumbs pathname={pathName} />
          <Row>
            <Col xs={12} lg={9}><h1 className={'mb-4 text-transform-none text-white'}>
              Get mad skills.
              <br />
              Hetzner Community is a place that’s designed for you to share, grow and improve.
            </h1></Col>
          </Row>
          <Row>
            <Col xs={12} lg={9}>
              <div className='shadow rounded'>
                {/* eslint-disable-next-line max-len */}
                <SearchField onSearch={e => this.onSearch(e)} value={props.search} totalAmountTutorials={props.totalAmountTutorials} tags={props.tags} />
              </div>
              <p className='mt-2 text-white'>
                <small>Find all the resources you need to get your projects off to a good start in our comprehensive
                  tutorial platform.
                </small>
              </p>
            </Col>
          </Row>
        </TutorialsHeader>

        <SupplementSection color='light-grey' />
        <section className='py-5'>
          <Container>
            <div className='mx-auto mb-4' style={{ maxWidth: '45rem' }}>
              {/* eslint-disable-next-line max-len */}
              <a href="https://github.com/hetzneronline/community-content?pk_campaign=community_cta&pk_source=community&pk_medium=cta&pk_content=get_rewarded_overview">
                {/* eslint-disable-next-line max-len */}
                <span className="text-center badge badge-pill badge-blue badge-responsive" style={{ padding: '.5rem 1.25rem' }}>
                  {/* eslint-disable-next-line max-len */}
                  <strong>Get Rewarded:</strong> We will give you up to <strong>€50</strong> credit on your account for every tutorial you write and we publish!
                </span>
              </a>
            </div>
            {/* eslint-disable-next-line max-len */}
            <div className='mx-auto mb-4 text-muted' style={{ maxWidth: '45rem' }}>{props.tutorials.length} tutorials found</div>
            <TutorialEntries.SearchPage tutorialClassName={'m-auto border-0'} tutorials={tutorialsOnPage} />
            {
              tutorialsOnPage.length > 0 &&
             <Pagination
               countTutorials={props.tutorials.length}
               onPageChanged={this.onPageChanged}
               itemsPerPage={itemsPerPage}
               currentPage={currentPage}
             />
            }
          </Container>
        </section>
      </main>
    </Layout>
  }
}

const TutorialPage = props => {
  return (
    <StaticQuery
      query={graphql`
      query{
        allMarkdownRemark( sort: { order: DESC, fields: [frontmatter___date] }){
          edges{
            node{
              frontmatter{
                title
                short_description
                author
                lang
                tags
                date
                path
                author_img
                author_link
              }
            }
          }
        }
      }
    `}
      render={tutorialData => <FilterProvider search={props.location.search} data={tutorialData} />}
    />
  )
}

export default TutorialPage
